import useJwt from '@/auth/jwt/useJwt'

export const isUserLoggedIn = () => {

  //console.log("isuserloggedin userdata", localStorage.getItem('userData'))
  //console.log("isuserloggedin token", localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName))
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))


// 2023-05-13 | fg | verificar si existe token para reset de contraseña
export const isResetMode = () => {
  return localStorage.getItem('reset_token')
}


export const resolveUserRole = ( type ) => {

  const roles = {
    0: 'admin',
    1: 'thumano',
    2: 'jefatura',
    3: 'seguridad',
    9: 'root'
  }

  return roles[type] ?? 'guest'

}


export const resolveUserRoleFull = ( type ) => {

  const roles = {
    0: 'administrador',
    1: 'talento humano',
    2: 'jefatura',
    3: 'analista de seguridad',
    9: 'root'
  }

  return roles[type] ?? 'guest'

}



export const getHomeRouteForLoggedInUser = userRole => {

  if (userRole === 'admin') return '/'
  if (userRole === 'jefatura') return { name: 'dashboard-solicitante' }
  return { name: 'dashboard-ejecutante' }
}
