

export default () => ({

  solicitudesCreacion: [],
  solicitudesModificacion: [],
  filtersCriteria: [],
  filtersCriteriaModificacion: []

})


