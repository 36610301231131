

export const setEmpleados = ( state, empleados ) => {
  state.empleados = [...empleados]

}
export const setFiltersCriteria = ( state, filtersCriteria ) => {

  state.filtersCriteria = [  ...filtersCriteria ]

}
