


export const fetchSolicitudes = (state) => (stringSearch = '') => {

  const solicitudes = state.solicitudesCreacion
  const activeFilters = state.filtersCriteria

  const solicitudesFiltered = solicitudes.filter( solicitudes => {

    if ( activeFilters.length === 0 )  return true

    let counter = 0

    activeFilters.forEach( currentFilter => {
      if ( currentFilter.values.includes(solicitudes[currentFilter.field]) )   counter ++
    })
    // incluir en la data filtrada solo los todos los criterios fueron cumplidos
    return counter === activeFilters.length
  })

  if ( stringSearch )
    return solicitudesFiltered.filter( solicitud => {
      return solicitud.empNombres.toLowerCase().includes( stringSearch.toLowerCase() )
          || solicitud.empApellidos.toLowerCase().includes( stringSearch.toLowerCase() )
          || solicitud.frmSecuencial.toString().includes( stringSearch.toLowerCase() )
    })

  return solicitudesFiltered
}

export const fetchFiltersCriteria = ( state ) => {

  return state.filtersCriteria
}

export const fetchModificacionSolicitudes = (state) => (stringSearch = '') => {

  const solicitudes = state.solicitudesModificacion
  const activeFilters = state.filtersCriteriaModificacion

  const solicitudesFiltered = solicitudes.filter( solicitudes => {

    if ( activeFilters.length === 0 )  return true

    let counter = 0

    activeFilters.forEach( currentFilter => {
      if ( currentFilter.values.includes(solicitudes[currentFilter.field]) )   counter ++
    })
    // incluir en la data filtrada solo los todos los criterios fueron cumplidos
    return counter === activeFilters.length
  })

  if ( stringSearch )
    return solicitudesFiltered.filter( solicitud => {
      return solicitud.empNombres.toLowerCase().includes( stringSearch.toLowerCase() )
          || solicitud.empApellidos.toLowerCase().includes( stringSearch.toLowerCase() )
          || solicitud.frmSecuencial.toString().includes( stringSearch.toLowerCase() )
    })


  return solicitudesFiltered
}

export const fetchModificationFiltersCriteria = ( state ) => {

  return state.filtersCriteriaModificacion
}