

export default () => ({

  ubicaciones: [],
  oficinas: [],
  cargos: [],
  areas: [],

})
