

import appApi from '@/modules/api/appApi'
import error from '@/views/pages/miscellaneous/Error.vue'

export const createAplicacion = async ( { commit }, data ) => {

  const response = await appApi.post( '/systems', data )

  if ( response.status === 422 )
    throw new Error( response.data )

  commit( 'addAplicacion', response.data )

}

export const getSistemas = async ( { commit } ) => {

  const { data } = await appApi.get( '/systems')

  console.log(data)

  commit( 'setSistemas', data )

}

export const findOne = async ( { commit }, params ) => {

  const { data } = await appApi.get('/systems/find-one', { params })

  return data

}

export const updateAplicacion = async ( { commit }, params ) => {

  const { sysUuid } = params

  //endpoint
  const { data }  = await appApi.patch(`/systems/${sysUuid}`, params)

  return data

}



export const listAplicaciones = async ( { commit }, params ) => {

  const { data } = await appApi.get('/systems/list', { params })

  return data
}

export const deleteAplicacion = async ( { commit }, uuid ) => {

  //endpoint
  const { data }  = await appApi.delete(`/systems/${uuid}`)



}
