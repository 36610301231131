
import appApi from '@/modules/api/appApi'

export const getEmployeesList = async ( { commit } ) => {
  const { data } = await appApi.get('/employees/list')
  return data
}

export const getEmployeeStatus = async ( { commit }, params ) => {
  const { data } = await appApi.get('/employees/status', { params })
  return data
}
