

export const fetchEmpleados = (state) => (stringSearch = '') => {

  const colaboradores = state.empleados
  const activeFilters = state.filtersCriteria

  const colaboradoresFiltered = colaboradores.filter( colaborador => {

    if ( activeFilters.length === 0 )  return true

    let counter = 0

    activeFilters.forEach( currentFilter => {
      if ( currentFilter.values.includes(colaborador[currentFilter.field]) )   counter ++
    })
    // incluir en la data filtrada solo los todos los criterios fueron cumplidos
    return counter === activeFilters.length
  })

  if ( stringSearch )
    return colaboradoresFiltered.filter( empleado => empleado.empNombres.toLowerCase().includes( stringSearch.toLowerCase() ))
  return colaboradoresFiltered
}
