



export default () => ({

  tasks: [],
  taskData: [],
  filtersCriteria: []
})
