export default [
    {
        path: '/solicitudes/modificacion-accesos',
        name: 'solicitudes-modificacion-accesos',
        component: () => import('@/modules/solicitante/views/solicitudes/SolicitudModificacionEdit.vue')
    },
    {
        path: '/dashboard-solicitante',
        name: 'dashboard-solicitante',
        component: () => import('@/modules/solicitante/views/dashboard/Dashboard.vue')
    },
    {
        path: '/solicitudes',
        name: 'solicitudes-menu',
        component: () => import('@/modules/solicitante/views/solicitudes/SolicitudesMenu.vue')
    },
    {
        path: '/solicitudes/creacion-accesos',
        name: 'solicitudes-creacion-accesos',
        component: () => import('@/modules/solicitante/views/solicitudes/creacion/SolicitudCreacionEdit.vue')
    },
    {
        path: '/documentos',
        name: 'documentos-carga',
        component: () => import('@/modules/solicitante/views/documentos/DocumentosCarga.vue')
    },

]
