
//2023-04-16



export default () => ({
  // variables de datos para compartir.
  /*.
  usuarios: []
  loading: false,
  userName: "nickname"
  */



})
