


export const setFormsCreation = ( state, data ) => {
  state.solicitudesCreacion = [...data]
}

export const setFormsModification = ( state, data ) => {
  state.solicitudesModificacion = [...data]
}
export const setFiltersCriteria = ( state, filtersCriteria ) => {

  state.filtersCriteria = [  ...filtersCriteria ]

}
export const setModificationFiltersCriteria = ( state, filtersCriteria ) => {

  state.filtersCriteriaModificacion = [  ...filtersCriteria ]

}