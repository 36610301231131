


import appApi from '@/modules/api/appApi'

export const createFormularioCreacion = async ( { commit }, formData ) => {

  const { data } = await appApi.post( '/forms', formData )

  return data
}


export const findOne = async ( { commit }, params ) => {

  const { data } = await appApi.get('/forms/find-one', { params })
  return data

}

// 2023-04-10 | fg | cargar solicitudes desde la BD
export const getFormsLatestStatus = async ( { commit }, params ) => {
  const { data } = await appApi.get('/forms/latest-status', { params })
  commit('setFormsCreation', data)
}
export const getModificationFormsLatestStatus = async ( { commit }, params ) => {
  const { data } = await appApi.get('/forms/latest-status', { params })
  commit('setFormsModification', data)
}


export const updateFormularioCreacion = async ( { commit }, formData ) => {
  const { frmUuid: uuid} = formData
  await appApi.patch( `/forms/${uuid}`, formData )
}


export const updateStatusFormularioCreacion = async ( { commit }, formData ) => {
  const { frmUuid: uuid} = formData
  await appApi.patch( `/forms/${uuid}/update-status`, formData )
}


export const cancelFormularioCreacion = async ( { commit }, formData ) => {

  await appApi.patch( `/forms/cancel`, formData )
}


// 2023-04-23 | fg | activar formulario creacion por primera vez
export const activarFormularioCreacion = async ( { commit }, params ) => {
  const { data } = await  appApi.post('/forms/activar-formulario',  params  )
  return data
}

export const createStatusResponse = async ( { commit }, status ) => {
  //endpoint
  const {  data  }  = await appApi.post(`/form-status`, status)
}


// 2023-05-20 | fg | enviar email al colaborador adjuntando solicitud y acuerdos
export const sendEmailSolicitudColaborador = async ( { commit }, params ) => {
  const { data } = await  appApi.post('/forms/mails/access-credentials-request',  params  )
  return data
}






//////////////////////// form systems

export const createFormSystemOffice = async ( { commit }, formData ) => {

  const { data }  = await appApi.post(`/form-systems/offices`, formData )

  return data

}
export const createFormSystem = async ( { commit }, formData ) => {

  const { data }  = await appApi.post(`/form-systems`, formData )

  return data

}

export const fetchFormSystems = async ( { commit }, params ) => {

  const { data } = await appApi.get('/form-systems', { params })
  return data

}

export const deleteFormSystem = async ( { commit }, id ) => {

  //endpoint
  const { data }  = await appApi.delete(`/form-systems/${id}`)

}

//2023-06-03 | fg | recuperar un registro por id
export const fetchFormSystemById = async ( { commit }, id ) => {

  const { data } = await appApi.get(`/form-systems/find-one-id/${id}`)
  return data

}





// Empleados que tienen asignado algun formulario de creacion
export const listEmpleados = async ( { commit }, params ) => {

  const { data }  = await appApi.get(`/forms/list-employees-forms`, { params })
  return data

}

export const listSystemWithRoles = async ( { commit } ) => {

  const { data }  = await appApi.get(`/forms/list-system-with-roles`)
  return data

}


// form status
export const fetchActividadFormulario = async ( { commit }, params ) => {

  const { data } = await appApi.get('/form-status/actividad-formulario', { params })

  return data

}

export const loadTaskForm = async ( { commit }, params ) => {
  const { data } = await appApi.get('/forms/tasks/find-one', { params })
  return data
}


