export const addAplicacion = ( state, aplicacion ) => {
  state.sistemas.unshift( aplicacion )

}

export const setSistemas = ( state, sistemas ) => {

  state.sistemas = [...sistemas]

}
