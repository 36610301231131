//2023-04-16

import appApi from '@/modules/api/appApi'

/* consulta directa a BASE DATOS
export const getSimple = async ( { commit } ) => {
  const { data } = await appApi.get('/nombre_recurso')
  commit('setData', data)   // guarda los datos obtenidos en el STORE, implementar en MUTATIONS
}
*/

//2023-05-19 | fg | guardar documento adjunto a una solicitud
export const createDocument = async ( { commit }, params ) => {
    const { data } = await appApi.post('/documents', params )
}


//2023-05-19 | fg | lista de documentos  relacionados a una solicitud
export const fetchDocuments = async ( { commit }, params ) => {
    const { data } = await appApi.get('/documents', { params })
    return data
}

//2023-05-19 | fg | borrar documento adjunto
export const deleteDocument = async ( { commit }, id ) => {
    const response = await appApi.delete(`/documents/${id}`)
}

//2023-05-21 | fg | recuperar pdf y mostrarlo al usuario
export const showDocument = async ( { commit }, uuid ) => {
    const { data } = await appApi.get(`/documents/show-file/${uuid}`, { responseType: 'blob' })
    return data
}




export const postFileNda = async ( { commit },formData ) => {

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    //console.log(params)
    const { data } = await appApi.post('/files/nda', formData, config)
    //commit('setData', data)   // guarda los datos obtenidos en el STORE, implementar en MUTATIONS
}

export const postFileKra = async ( { commit },params ) => {

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    const { data } = await appApi.post('/files/kra', params, config)
    //commit('setData', data)   // guarda los datos obtenidos en el STORE, implementar en MUTATIONS
}

export const getFindDoc = async ( { commit },  params ) => {

    //console.log("ACTION",params)
    return await appApi.post('/files/find-doc', params)
    //commit('setData', data)   // guarda los datos obtenidos en el STORE, implementar en MUTATIONS
}
