import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/modules/auth/utils'



import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

// 2023-02-09 | fg | rutas iniciales
import ejecutante from '@/modules/ejecutante/router'
import solicitante from '@/modules/solicitante/router'
import seguridad from '@/modules/seguridad/router'
import authentication from '@/modules/auth/router'
import modules from '@/modules/router'

import { isResetMode } from '@/modules/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/', redirect: to => {

        const isLoggedIn = isUserLoggedIn()

        if ( isLoggedIn ) {

          const roleRedirectHome = {
            root: { name: 'dashboard-ejecutante' },
            admin: { name: 'dashboard-ejecutante' },
            thumano: { name: 'dashboard-solicitante' },
            jefatura: { name: 'dashboard-solicitante' },
            seguridad: { name: 'dashboard-ejecutante' },
            // Agrega más mapeos de roles y rutas según sea necesario
          };

          const userData = getUserData()

          if ( userData )
            return roleRedirectHome[userData.role]
          else
            return { name: 'error-404' }
        }

      }
    },

    ...authentication,
    ...solicitante,
    ...ejecutante,
    ...modules,
    ...seguridad,


    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {

  // eliminar token reset antes de entrar a ruta login
  if ( to.name === 'auth-signin' )  localStorage.removeItem('reset_token')
  // verificar si es acceso reset
  const resetToken = isResetMode()


  /*
  console.log("before each", to, next)
  console.log("reset token", resetToken)

   */


  if ( resetToken )  {
    // verificar si ya estamos en la ruta de reset
    if (to.name === 'auth-reset') {
      return next()
    } else {
      return next({ name: 'auth-reset' })
    }

  } else {

    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: 'auth-signin' })

      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()

  }

})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
