/* eslint-disable */
export default [
  {
    path: '/dashboard-ejecutante',
    name: 'dashboard-ejecutante',
    component: () => import('@/modules/ejecutante/views/dashboard/Dashboard.vue')
  },

  {
    path: '/catalogos/usuarios',
    name: 'catalogo-usuarios',
    component: () => import('@/modules/ejecutante/views/catalogos/usuarios/UsuariosList.vue')
  },
  {
    path: '/catalogos/usuarios/view',
    name: 'catalogo-usuarios-view',
    component: () => import('@/modules/ejecutante/views/catalogos/usuarios/UsuarioView.vue'),

  },

  {
    path: '/catalogos/oficinas',
    name: 'catalogo-oficinas',
    component: () => import('@/modules/ejecutante/views/catalogos/oficinas/Oficinas.vue')
  },
  {
    path: '/catalogos/cargos',
    name: 'catalogo-cargos',
    component: () => import('@/modules/ejecutante/views/catalogos/cargos/Cargos.vue')
  },
  {
    path: '/catalogos/empleados',
    name: 'catalogo-colaborador',
    component: () => import('@/modules/ejecutante/views/catalogos/empleados/Empleados.vue')
  },
  {
    path: '/catalogos/empleados-edit',
    name: 'catalogo-empleados-edit',
    component: () => import('@/modules/ejecutante/views/catalogos/empleados/EmpleadosEdit.vue')
  },
  {
    path: '/catalogos/empleados-list',
    name: 'catalogo-empleados-list',
    component: () => import('@/modules/ejecutante/views/catalogos/empleados/EmpleadosList.vue')
  },
  {
    path: '/catalogos/empleados-view',
    name: 'catalogo-empleados-view',
    component: () => import('@/modules/ejecutante/views/catalogos/empleados/EmpleadosView.vue')
  },
  {
    path: '/catalogos/aplicaciones',
    name: 'catalogo-aplicaciones',
    component: () => import('@/modules/ejecutante/views/catalogos/aplicaciones/Aplicaciones.vue')
  },
  {
    path: '/catalogos/aplicaciones',
    name: 'catalogo-aplicaciones-view',
    component: () => import('@/modules/ejecutante/views/catalogos/aplicaciones/AplicacionesView.vue')
  },


  {
    path: '/tareas',
    name: 'tareas',
    component: () => import('@/modules/ejecutante/views/tareas/Tareas.vue')
  },
  {
    path: '/tareas/open',
    name: 'tareas-open',
    component: () => import('@/modules/ejecutante/views/tareas/TareaOpen.vue')
  },

]
