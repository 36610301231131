export default [
  {
    path: '/seguridad/auditoria',
    name: 'seguridad-auditoria',
    component: () => import('@/modules/seguridad/views/AuditoriaList.vue')
  },
  {
    path: '/consultas/colaborador',
    name: 'consultas-colaborador',
    component: () => import('@/modules/seguridad/views/consultas/Colaborador.vue')
  },
]
