


export const setTasks = ( state, data ) => {
  state.tasks = [...data]
}


export const setTaskData = ( state, data ) => {
  state.taskData = data
}

export const setFiltersCriteria = ( state, filtersCriteria ) => {

  state.filtersCriteria = [  ...filtersCriteria ]

}


