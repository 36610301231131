export const addLog = ( state, auditoria ) => {
  state.logs.unshift( auditoria )

}

export const setLogs = ( state, auditoria ) => {

  state.logs = [...auditoria]

}

export const setFiltersCriteria = ( state, filtersCriteria ) => {

  state.filtersCriteria = [  ...filtersCriteria ]

}

export const setFilterDate = ( state, date ) => {

  state.filterDate = date

}
