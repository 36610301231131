

import axios from 'axios'


const appApi = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://127.0.0.1:8000/api',
     baseURL: 'http://apisga.coop23dejulio.fin.ec/api',
   //baseURL: 'http://apisga-test.coop23dejulio.fin.ec/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

export default appApi
