export const fetchLogsAuditoria = ( state ) => ( stringSearch = '' ) => {

  // procesar filtros

  const logs = state.logs

  const activeFilters = state.filtersCriteria

  const activeDates = state.filterDate

  let startDate, endDate, logsDates

  logsDates = logs

  if (activeDates.length) {
    startDate = activeDates[0]
    endDate = startDate
    if (activeDates.length == 2) endDate = activeDates[1]

    // Filtrar el arreglo por el rango de fechas
      logsDates = logs.filter(item => {
      // Obtener la fecha del elemento actual
      const fechaItem = item.audFecha.split(' ')[0];

      // Comparar si la fecha del elemento está dentro del rango
      return fechaItem >= startDate && fechaItem <= endDate;
    });

  }

   const logsFiltered = logsDates.filter( logs => {

    if ( activeFilters.length === 0 )  return true

    let counter = 0

    activeFilters.forEach( currentFilter => {
      if ( currentFilter.values.includes(logs[currentFilter.field]) )   counter ++
    })
    // incluir en la data filtrada solo los todos los criterios fueron cumplidos
    return counter === activeFilters.length
  })


  if ( stringSearch )
    return logsFiltered.filter( logs => {
      return logs.usrNombres.toLowerCase().includes( stringSearch.toLowerCase() )
        ||  logs.usrApellidos.toLowerCase().includes( stringSearch.toLowerCase() )
        || logs.email.toLowerCase().includes( stringSearch.toLowerCase() )
        || logs.audDetalle.toLowerCase().includes( stringSearch.toLowerCase() )
    })


  return logsFiltered

}

export const fetchUniqueUsers = ( state )  => {

  const logs = state.logs

  let uniqueUsersList = [];

  logs.forEach((item) => {
    if (!uniqueUsersList.some(user => user.id === item.id)) {
      uniqueUsersList.push({
        audId: item.audId,
        usrNombres: item.usrNombres,
        usrApellidos: item.usrApellidos,
        email: item.email,
        id: item.id,
        fullName: item.usrNombres + ' ' + item.usrApellidos
      });
    }
  });

  uniqueUsersList.sort((a, b) => {
    const fullNameA = a.fullName.toLowerCase();
    const fullNameB = b.fullName.toLowerCase();

    if (fullNameA < fullNameB) {
      return -1;
    } else if (fullNameA > fullNameB) {
      return 1;
    } else {
      return 0;
    }
  });

  return uniqueUsersList

}
