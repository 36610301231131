

import appApi from '@/modules/api/appApi'

export const getUsuarios = async ( { commit } ) => {

  const { data } = await appApi.get('/users')
  commit('setUsuarios', data)

}


export const createUsuario = async ( { commit }, usuario ) => {

  //endpoint
  const {  data  }  = await appApi.post(`/auth/create-user`, usuario)


}



export const findOne = async ( { commit }, params ) => {

  const { data } = await appApi.get('/users/find-one', { params })

  return data

}



export const updateUsuario = async ( { commit }, params ) => {

  const { usrUuid } = params

  //endpoint
  const { data }  = await appApi.patch(`/users/${usrUuid}`, params)

  return data

}



export const deleteUsuario = async ( { commit }, uuid ) => {

  //endpoint
  const { data }  = await appApi.delete(`/users/${uuid}`)


}


export const unlockUsuario = async ( { commit }, id ) => {

  //endpoint
  const { data }  = await appApi.patch(`/users/${id}/unlock`)

  console.log("action response", data)


}

