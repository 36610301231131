

import appApi from '@/modules/api/appApi'


/*
2023-05-07 | fg | lista de tareas
 */
export const getTasksLatestStatus = async ( { commit }, params ) => {
  const { data } = await appApi.get('/forms/tasks-latest-status', { params })
  commit('setTasks', data)
}


/*
Formulario de tarea individual con referencias
 */
export const loadTaskForm = async ( { commit }, params ) => {
  const { data } = await appApi.get('/forms/tasks/find-one', { params })
  commit('setTaskData', data)
}



/*
Formulario de tarea individual con referencias
 */
export const getTaskForm = async ( { commit }, params ) => {
  const { data } = await appApi.get('/forms/tasks/find-one', { params })
  return data
}

// 2023-05-08 | fg | historial del formulario de tareas
export const fetchActividadFormularioTarea = async ( { commit }, params ) => {

  const { data } = await appApi.get('/form-status/actividad-formulario', { params })

  return data

}


// 2023-05-14 | fg | guardar respuesta del usuario

export const createStatusResponse = async ( { commit }, status ) => {
  //endpoint
  const {  data  }  = await appApi.post(`/form-status`, status)
}

// 2023-06-02 | fg | registrar credenciales en tarea creacion accesos
export const updateCredentials = async ( { commit }, data ) => {

  const response = await appApi.patch(`/form-systems/update-credentials`, data  )

  return response
}

// 2023-06-04 | fg | enviar correo a colaborador

export const sendEmailCredencialesColaborador = async ( { commit }, params ) => {
  const { data } = await  appApi.post('/forms/mails/access-credentials-notification',  params  )
  return data
}



// 2023-06-04 | fg | restablecer credenciales en tarea creacion accesos
export const resetCredentials = async ( { commit }, data ) => {

  const response = await appApi.patch(`/form-systems/reset-credentials`, data  )

  return response
}





