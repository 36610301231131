import appApi from '@/modules/api/appApi'


export const getEstadisticas = async ( { commit }, params ) => {

  const { data } = await appApi.get('/dashboard-solicitante/estadisticas', { params })

  return data;

}

export const getCompletadas = async ( { commit }, params ) => {

  const { data } = await appApi.get('/dashboard-solicitante/completadas', { params })

  return data;

}

export const getSolicitudesMes = async ( { commit }, params  ) => {

  const { data } = await appApi.get('/dashboard-solicitante/solicitudes-mes', { params })
  return data;

}


export const getSolicitudesUltimas = async ( { commit }, params ) => {

  const { data } = await appApi.get('/dashboard-solicitante/solicitudes-ultimas', { params })

  return data;

}
