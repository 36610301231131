

export const fetchUsuarios = (state) => (stringSearch = '') => {

  // procesar filtros

  const usuarios = state.usuarios
  const activeFilters = state.filtersCriteria

  const usuariosFiltered = usuarios.filter( usuario => {

    if ( activeFilters.length === 0 )  return true

    let counter = 0

    activeFilters.forEach( currentFilter => {
      if ( currentFilter.values.includes(usuario[currentFilter.field]) )   counter ++
    })
    // incluir en la data filtrada solo los todos los criterios fueron cumplidos
    return counter === activeFilters.length
  })


  if ( stringSearch )
    return usuariosFiltered.filter( usuario => {
      return usuario.usrNombres.toLowerCase().includes( stringSearch.toLowerCase() )
      ||  usuario.usrApellidos.toLowerCase().includes( stringSearch.toLowerCase() )
      || usuario.email.toLowerCase().includes( stringSearch.toLowerCase() )
    })

  return usuariosFiltered
}


