

export const fetchUbicaciones = ( state ) => {
  return state.ubicaciones
}

export const fetchOficinas = ( state ) => ( ubicacion = null ) => {

  if ( ubicacion != null )
    return state.oficinas.filter( oficina => oficina.plaId == ubicacion )

  return state.oficinas
}


export const fetchCargos = (state) => {
  return state.cargos
}
export const fetchAreas = (state) => {
  return state.areas
}

export const fetchCargosFilter = ( state ) => ( stringSearch = '' ) => {

  if ( stringSearch )
    return state.cargos.filter( cargo => cargo.posNombre.toLowerCase().includes( stringSearch.toLowerCase() ) )

  return state.cargos
}
