

export const setUsuarios = ( state, usuarios ) => {
  state.usuarios = [...usuarios]

}

export const setFiltersCriteria = ( state, filtersCriteria ) => {

  state.filtersCriteria = [  ...filtersCriteria ]

}



