

export const setUbicaciones = ( state, ubicaciones ) => {

  state.ubicaciones = [...ubicaciones]

}



export const setOficinas = ( state, oficinas ) => {

  state.oficinas = [...oficinas]

}
export const setCargos = ( state, cargos ) => {

  state.cargos = [...cargos]

}
export const setAreas = ( state, areas ) => {

  state.areas = [...areas]

}
