import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// sga modules
import sgaModule from '@/modules/store'
import empleadoModule from '@/modules/ejecutante/store/empleados'
import aplicacionModule from '@/modules/ejecutante/store/aplicaciones'
import roleModule from '@/modules/ejecutante/store/roles'
import usuarioModule from '@/modules/ejecutante/store/usuarios'
import solicitanteModule from '@/modules/solicitante/store'
import solicitanteSolicitudesModule from '@/modules/solicitante/store/solicitudes'
import fileModule from "@/modules/solicitante/store/documentos";
import dashboardSolicitudesModule from '@/modules/solicitante/store/dashboard';
import dashboardEjecutanteModule from '@/modules/ejecutante/store/dashboard'
import tasksModule from '@/modules/ejecutante/store/tareas'
import logsModule from '@/modules/seguridad/store/auditoria'
import securityModule from '@/modules/seguridad/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {

    'sga-module': sgaModule,
    'empleado-module': empleadoModule,
    'aplicacion-module': aplicacionModule,
    'role-module': roleModule,
    'usuario-module': usuarioModule,
    'solicitante-module': solicitanteModule,
    'solicitante-solicitudes-module': solicitanteSolicitudesModule,
    'file-module': fileModule,
    'dashboard-module': dashboardSolicitudesModule,
    'dashboard-ejecutante': dashboardEjecutanteModule,
    'tasks-module': tasksModule,
    'logs-module':logsModule,
    'security-module': securityModule,

    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
