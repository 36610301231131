

import appApi from '@/modules/api/appApi'


export const getUbicaciones = async ( { commit } ) => {

  const { data } = await appApi.get('/places')

  commit('setUbicaciones', data)

}


export const getOficinas = async ( { commit } ) => {

  const { data } = await appApi.get('/offices')

  commit('setOficinas', data)

}
export const getCargos = async ( { commit } ) => {

  const { data } = await appApi.get('/positions')

  commit('setCargos', data)

}
export const getAreas = async ( { commit } ) => {

  const { data } = await appApi.get('/sections')

  commit('setAreas', data)

}


export const getFormTypes = async ( { commit } ) => {

  const { data } = await appApi.get('/form-types')

  return data

}

export const findOneFormType = async ( { commit }, params ) => {

  const { data } = await appApi.get('/form-types/find-one', { params })

  return data

}

export const findFormType = async ( { commit }, params ) => {

  const { data } = await appApi.get('/form-types/find', { params })
  //console.log(data)
  return data

}

export const findOneId = async ( { commit }, params ) => {

  const { data } = await appApi.get('/positions/find-one-id', { params })

  return data

}

export const updateCargo = async ( { commit }, params ) => {

  const { sysUuid } = params

  //endpoint
  const { data }  = await appApi.patch(`/positions/${sysUuid}`, params)

  return data

}


