



export const fetchTasks = (state) => (stringSearch = '') => {

  const tareas = state.tasks
  const activeFilters = state.filtersCriteria

  const tareasFiltered = tareas.filter( tareas => {

    if ( activeFilters.length === 0 )  return true

    let counter = 0

    activeFilters.forEach( currentFilter => {
      if ( currentFilter.values.includes(tareas[currentFilter.field]) )   counter ++
    })
    // incluir en la data filtrada solo los todos los criterios fueron cumplidos
    return counter === activeFilters.length
  })

  if ( stringSearch )
    return tareasFiltered.filter( tareas => {
      return tareas.empNombres.toLowerCase().includes( stringSearch.toLowerCase() )
          || tareas.empApellidos.toLowerCase().includes( stringSearch.toLowerCase() )
          || tareas.frmSecuencial.toString().includes( stringSearch.toLowerCase() )
    })
  return tareasFiltered
}


export const fetchTaskData = (state) => {

  return state.taskData


}


