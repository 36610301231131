import appApi from '@/modules/api/appApi'


export const getEstadisticasEjecutante = async ( { commit }, params ) => {

  const { data } = await appApi.get('/dashboard-ejecutante/estadisticas', { params })

  return data;

}

export const getCompletadas = async ( { commit }, params ) => {

  const { data } = await appApi.get('/dashboard-solicitante/completadas', { params })

  return data;

}

export const getSolicitudesMesEjecutante = async ( { commit }, params  ) => {

  const { data } = await appApi.get('/dashboard-ejecutante/solicitudes-mes', { params })
  return data;

}


export const getSolicitudesUltimas = async ( { commit }, params ) => {

  const { data } = await appApi.get('/dashboard-solicitante/solicitudes-ultimas', { params })

  return data;

}
