

import appApi from '@/modules/api/appApi'

export const getEmpleados = async ( { commit } ) => {

  const { data } = await appApi.get('/employees')
  commit('setEmpleados', data)

}
export const createEmpleado = async ( { commit }, data ) => {
  const response = await appApi.post('/employees',data)
  if ( response.status === 422 )
    throw new Error(response.data)

  //console.log(response)
}
export const findOne = async ( { commit }, params ) => {

  const { data } = await appApi.get('/employees/find-one', { params })

  return data

}

export const find = async ( { commit }, params ) => {

  const { data } = await appApi.get('/employees/find', { params })

  return data

}


export const updateEmpleado = async ( { commit }, params ) => {

  const { empUuid } = params

  //endpoint
  const { data }  = await appApi.patch(`/employees/${empUuid}`, params)

  return data

}

export const listEmpleados = async ( { commit }, params ) => {

  const { data }  = await appApi.get(`/employees`, params)

  return data

}

export const deleteEmpleados = async ( { commit }, uuid ) => {

  //endpoint
  const { data }  = await appApi.delete(`/employees/${uuid}`)


}

